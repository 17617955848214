import React from 'react'
import NextLink from 'next/link'
import Menu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import { Button } from '@mui/material'
import { motion } from 'framer-motion'
import clsx from 'clsx'

import {
  ExternalRoute,
  InternalRoute,
  NestedRoutes,
  routeConfig,
} from '@Config/routes/route-config'
import { MenuItem } from '@Components/elements/Link/menu-item'
import { useActiveClass } from '@Utils/hooks/use-active-class'
import { childrenVariants } from '@Config/framer/variants'
import { transitionChildren } from '@Config/framer/transitions'

import menuItemStyles from '@Components/elements/Link/menu-item.module.scss'
import styles from './desktop.module.scss'

interface PositionedMenuProps {
  id: string
  buttonText: string
  nestedRoutes: NestedRoutes
  linkClass?: string
  dropdownButtonClass: string
}

interface MenuItemProps {
  route: InternalRoute | ExternalRoute
  linkClass?: string
}

interface DropdownButtonProps {
  route: InternalRoute
  onClick?: () => void
  linkClass?: string
  dropdownButtonClass: string
}

/**
 * sorts external and internal links
 */
const MenuLink = React.forwardRef<any, MenuItemProps>(
  ({ route, linkClass }, ref) =>
    React.useMemo(
      () => (
        <>
          {'pathname' in route ? (
            <MenuItem
              nextLinkProps={{
                href: route.pathname,
              }}
              ref={ref}
              className={linkClass}
            >
              {route.prettyName}
            </MenuItem>
          ) : (
            // external link
            <MenuItem
              nextLinkProps={{
                href: route.url,
              }}
              ref={ref}
              className={linkClass}
            >
              {route.prettyName}
            </MenuItem>
          )}
        </>
      ),
      [ref, route, linkClass],
    ),
)
MenuLink.displayName = 'MenuLink'
/**
 * sorts external and internal links
 */
const DropdownButton = React.forwardRef<any, DropdownButtonProps>(
  ({ route, linkClass, onClick, dropdownButtonClass }, ref) => {
    const { className } = useActiveClass({
      classNames: clsx(dropdownButtonClass, linkClass),
      pathname: route.pathname,
      parentRouteSlugs: [route.pathname],
      activeClassName: 'active',
    })
    return React.useMemo(
      () => (
        <NextLink href={route.pathname} passHref>
          <MuiMenuItem
            disableRipple
            onClick={onClick}
            onKeyDown={onClick}
            ref={ref}
            className={className}
          >
            {route.prettyName}
          </MuiMenuItem>
        </NextLink>
      ),
      [onClick, ref, route, className],
    )
  },
)

function PositionedMenu({
  id,
  buttonText,
  nestedRoutes,
  linkClass,
  dropdownButtonClass,
}: PositionedMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
    null,
  )

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const { className } = useActiveClass({
    activeClassName: 'active',
    parentRouteSlugs: nestedRoutes.map((route) => route.pathname),
    classNames: linkClass,
  })

  return React.useMemo(
    () => (
      <>
        <Button
          size={'large'}
          aria-controls={id}
          aria-haspopup='true'
          onClick={handleClick}
          onMouseOver={handleClick}
          className={clsx(className, menuItemStyles.menuItemLabel)}
          disableRipple
          component={motion.button}
          variants={childrenVariants}
          transition={transitionChildren}
        >
          {buttonText}
        </Button>
        <Menu
          id={id}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          aria-expanded={Boolean(anchorEl)}
          onClose={handleClose}
          disableScrollLock
          MenuListProps={{ onMouseLeave: handleClose }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {nestedRoutes.map((route) => (
            <DropdownButton
              onClick={handleClose}
              key={route.prettyName}
              route={route}
              linkClass={linkClass}
              dropdownButtonClass={dropdownButtonClass}
            />
          ))}
        </Menu>
      </>
    ),
    [
      nestedRoutes,
      buttonText,
      id,
      linkClass,
      anchorEl,
      className,
      dropdownButtonClass,
    ],
  )
}

export function NavLinks() {
  //todo add newsletter button using the ctx
  // if newsletter box open, do nothing
  return React.useMemo(
    () => (
      <>
        {routeConfig.map((route) =>
          'key' in route ? (
            <PositionedMenu
              key={route.key}
              id={route.key}
              buttonText={route.prettyName}
              nestedRoutes={route.childRoutes}
              linkClass={styles.buttonBase}
              dropdownButtonClass={styles.dropdownButton}
            />
          ) : (
            <MenuLink
              linkClass={styles.buttonBase}
              route={route}
              key={route.prettyName}
            />
          ),
        )}
      </>
    ),
    [],
  )
}
