export interface ExternalRoute {
  url: string
  prettyName: string // the name of the link
}

export interface InternalRoute {
  pathname: string // the path, ie. '/about-us'
  slug: string // the slug, ie. 'about-us', used for key
  prettyName: string // the name of the link
}

export type NestedRoutes = ReadonlyArray<InternalRoute>

// ie, button with dropdown menu
export interface ParentRoute {
  prettyName: string
  key: string
  childRoutes: NestedRoutes
}

type Route = ExternalRoute | InternalRoute | ParentRoute

export const routeConfig: ReadonlyArray<Route> = [
  {
    pathname: '/',
    slug: 'home',
    prettyName: 'Home',
  },
  // {
  //   //todo make this a single route
  //   key: 'gallery-menu',
  //   prettyName: 'Art Gallery',
  //   childRoutes: [

  //     //todo remove
  //     {
  //       pathname: '/exhibitions',
  //       slug: 'exhibitions',
  //       prettyName: 'Exhibitions',
  //     },
  //   ],
  // },
  {
    pathname: '/lounge',
    slug: 'lounge',
    prettyName: 'Lounge',
  },
  // {
  //   pathname: '/menu',
  //   slug: 'menu',
  //   prettyName: 'Menu',
  // },
  {
    pathname: '/hookah',
    slug: 'hookah',
    prettyName: 'Hookah',
  },
  // {
  //   pathname: '/about',
  //   slug: 'about',
  //   prettyName: 'About',
  // },
  // {
  //   //todo make external link when creative little's is set up
  //   prettyName: 'Our Charity',
  //   slug: 'our-charity',
  //   pathname: '/our-charity',
  // },
]
