import { useRouter } from 'next/router'
import clsx from 'clsx'
import { useMemo } from 'react'

interface UseActiveClass {
  pathname?: string
  activeClassName: string
  classNames?: string
  parentRouteSlugs?: ReadonlyArray<string>
}

/**
 * Get active on links and child links. Returns the class to apply to styles and the boolean if it is active.
 *
 * @param classNames
 * @param pathname
 * @param activeClassName
 * @param parentRouteSlugs
 */
export const useActiveClass = ({
  classNames,
  pathname,
  activeClassName,
  parentRouteSlugs,
}: UseActiveClass) => {
  const router = useRouter()
  const active = useMemo(
    () =>
      !parentRouteSlugs
        ? router.pathname === pathname
        : parentRouteSlugs.indexOf(router.pathname) > -1 ||
          // for dynamic inner routes
          parentRouteSlugs.some((route) =>
            router.pathname.startsWith(route),
          ),
    [router.pathname, pathname, parentRouteSlugs],
  )

  const className = useMemo(
    () =>
      clsx(classNames, {
        [activeClassName]: active && activeClassName,
      }),
    [active, activeClassName, classNames],
  )

  return {
    className,
    active,
  }
}
