import React from 'react'
import { styled } from '@mui/material/styles'

import { NavLinks } from '@Components/Layout/Header/Desktop/nav-links'
import { SocialIcons } from '@Components/elements/SocialIcons/social-icons'

const Root = styled('nav')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}))

function DesktopNav() {
  return (
    <Root>
      <NavLinks />
      <SocialIcons isSmallScreen={false} />
    </Root>
  )
}

export default DesktopNav
