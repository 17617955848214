import React from 'react'
import { styled } from '@mui/material/styles'
import Image from 'next/image'
import { motion, Variants } from 'framer-motion'
import { transitionChildren } from '@Config/framer/transitions'
import facebookIcon from '@Static/images/brands/facebook_icon.png'
import instagramIcon from '@Static/images/brands/instagram_icon.png'
// import tiktokIcon from '@Static/images/brands/tiktok-round.png'

const PREFIX = 'SocialIcons'

const classes = {
  socialIcon: `${PREFIX}-socialIcon`,
  socialIconWrap: `${PREFIX}-socialIconWrap`,
  image: `${PREFIX}-image`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.socialIcon}`]: {
    'margin': theme.spacing(0.5),
    'display': 'inline-block',
    'position': 'relative',
    'overflow': 'visible',
    '& > div, & > span': {
      overflow: 'visible !important',
    },
    '&:focus': {
      border: `1px solid ${theme.palette.text.primary}`,
    },
  },

  [`& .${classes.socialIconWrap}`]: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    padding: theme.spacing(1, 2),
  },

  [`& .${classes.image}`]: {
    'objectFit': 'contain',
    'maxWidth': '100%',
    'width': 'auto',
    'transform': 'translateY(0)',
    'transition': '200ms transform cubic-bezier(0.8, 0, 0.2, 1)',
    '&:hover, &:focus': {
      transform: 'translateY(-2px)',
    },
  },
}))

const variantsDesktop: Variants = {
  pageAnimate: {
    translateY: 0,
    opacity: 1,
  },
  pageInitial: {
    translateY: -5,
    opacity: 0,
  },
  pageExit: {
    translateY: -7,
    opacity: 0,
  },
}

const variantsMobile: Variants = {
  navOpen: {
    opacity: 1,
  },
  navClosed: {
    opacity: 0,
  },
}

export const SocialIcons = ({
  isSmallScreen,
}: {
  isSmallScreen: boolean
}) => {
  return React.useMemo(
    () => (
      <Root className={classes.socialIconWrap}>
        <motion.a
          variants={isSmallScreen ? variantsMobile : variantsDesktop}
          transition={transitionChildren}
          href={'https://www.facebook.com/zcospaceclubhouse'}
          rel={'noopener noreferrer'}
          target={'_blank'}
          className={classes.socialIcon}
        >
          <Image
            className={classes.image}
            src={facebookIcon}
            width={36}
            height={36}
            alt={'facebook'}
            loading={'lazy'}
          />
        </motion.a>
        <motion.a
          variants={isSmallScreen ? variantsMobile : variantsDesktop}
          transition={transitionChildren}
          href={'https://www.instagram.com/zcospaceclubhouse/'}
          rel={'noopener noreferrer'}
          target={'_blank'}
          className={classes.socialIcon}
        >
          <Image
            className={classes.image}
            src={instagramIcon}
            width={36}
            height={36}
            alt={'instagram'}
            loading={'lazy'}
          />
        </motion.a>
      </Root>
    ),
    [isSmallScreen],
  )
}
