import Link, { LinkProps } from 'next/link'
import React, { ReactNode, ReactNodeArray } from 'react'
import { motion } from 'framer-motion'
import Button from '@mui/material/Button'
import clsx from 'clsx'
import { useActiveClass } from '@Utils/hooks/use-active-class'
import { childrenVariants } from '@Config/framer/variants'
import { transitionChildren } from '@Config/framer/transitions'
import styles from './menu-item.module.scss'

export type MuiMenuItemProps = {
  children: ReactNode | ReactNodeArray
  nextLinkProps: Omit<LinkProps, 'passHref'>
  activeClassName?: string
  className?: string
}

/**
 * navigation menu item for internal routes
 */
export const MenuItem = React.forwardRef<any, MuiMenuItemProps>(
  (
    {
      nextLinkProps,
      activeClassName = 'active',
      className: classNameProps,
      children,
    },
    ref,
  ) => {
    const { href } = nextLinkProps
    const isExternal =
      typeof href === 'string' &&
      (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0)

    const pathname = typeof href === 'string' ? href : href.pathname
    const { active, className } = useActiveClass({
      classNames: clsx(styles.menuItemLabel, classNameProps),
      pathname: pathname ?? '/',
      activeClassName,
    })

    if (isExternal) {
      return (
        <Button
          size={'large'}
          disableRipple
          aria-selected={active}
          aria-current={active}
          ref={ref}
          className={className}
          component={motion.a}
          variants={childrenVariants}
          transition={transitionChildren}
          href={pathname ?? undefined}
          target={'_blank'}
          rel={'noreferrer noopener'}
        >
          {children}
        </Button>
      )
    }
    return (
      <Link {...nextLinkProps} passHref>
        <Button
          disableRipple
          size={'large'}
          aria-selected={active}
          aria-current={active}
          ref={ref}
          className={className}
          component={motion.a}
          variants={childrenVariants}
          transition={transitionChildren}
        >
          {children}
        </Button>
      </Link>
    )
  },
)
